* {
	box-sizing: border-box;
}

:root {
	--white: #fff;
	--red: #b30000;


	--lightGray: #d9d9d9;
	--darkGray: #212121;
	--darkGray2: #4e5350;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* *{
	font-family: "Urbanist", sans-serif !important;
} */
body,
html {
	font-family: "Urbanist", sans-serif;
	/* font-family: "Open Sans", sans-serif; */
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: #777;
	font-weight: 400;
	width: 100% !important;
	height: 100% !important;
}

h2,
h3,
h4 {
	font-family: "Raleway", sans-serif;
	cursor: default;
}

h2 {
	text-transform: uppercase;
	margin: 0 0 20px;
	font-weight: 900;
	font-size: 30px;
	color: #333;
}

h3 {
	font-size: 20px;
	font-weight: 800;
	color: #333;
}

h4 {
	font-size: 18px;
	color: #333;
	font-weight: 600;
}

h5 {
	text-transform: uppercase;
	font-weight: 700;
	line-height: 20px;
	cursor: default;
}

p {
	font-size: 15px;
	cursor: default;
}

p.intro {
	margin: 12px 0 0;
	line-height: 24px;
}

a {

	font-weight: 400;
}

a:hover,
a:focus {
	text-decoration: none;

}

ul,
ol {
	list-style: none;
}

ul,
li {
	font-weight: 300;
}

ul,
ol {
	padding: 0;
	webkit-padding: 0;
	moz-padding: 0;
}

hr {
	height: 2px;
	width: 70px;
	text-align: center;
	position: relative;
	background: #1e7a46;
	margin-bottom: 20px;
	border: 0;
}

/* Navigation */

#navbar {
	position: fixed;
	z-index: 10;
	background-color: white;
	width: 100%;
}

#navbar .container {
	display: flex;
	align-items: center;
}

#navbar .navlink {
	width: fit-content;
	margin: 0 auto;
	text-decoration: none;

}

#navbar .navlink p {
	color: var(--red);
	text-decoration: none;
	font-weight: 600;
	cursor: pointer;
	transition: font-weight 1s, text-decoration 1s;
}

#navbar .navlink p:hover,
#navbar .navlink-active p {
	font-weight: 900;
}

#navbar .logo {
	margin-left: 0px;
	max-width: 200px;
}

#navbar .logo img {
	width: 100%;
	object-fit: contain;
}

#navbar .logo .link {
	display: none;
}

#navbar svg {
	display: none;
}


/* #menu {
	padding: 15px;
	transition: all 0.8s;
}

#menu.navbar-default {
	background-color: #fff;
	border-color: rgba(231, 231, 231, 0);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

/*#menu a.navbar-brand {
	font-family: "Raleway", sans-serif;
	font-size: 24px;
	font-weight: 700;
	color: #333;
	text-transform: uppercase;
}
img.nav-logo {
	width: 130px;
	margin-top: -6px;
}

#menu.navbar-default .navbar-nav>li>a {
	font-family: "Lato", sans-serif;
	text-transform: uppercase;
	color: #555;
	font-size: 15px;
	font-weight: 400;
	padding: 8px 2px;
	border-radius: 0;
	margin: 9px 20px 0;
}

#menu.navbar-default .navbar-nav>li>a:after {
	display: block;
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 0;
	height: 2px;
	background: linear-gradient(to right, #5f0000 0%, #b30000 100%);
	background: linear-gradient(to right, #5f0000 0%, #b30000 100%);
	content: "";
	transition: width 0.2s;
}

#menu.navbar-default .navbar-nav>li>a:hover:after {
	width: 100%;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
	background-color: transparent;
}

.navbar-default .navbar-nav>.active>a:after,
.navbar-default .navbar-nav>.active>a:hover:after,
.navbar-default .navbar-nav>.active>a:focus:after {
	display: block !important;
	position: absolute !important;
	left: 0 !important;
	bottom: -1px !important;
	width: 100% !important;
	height: 2px !important;
	background: linear-gradient(to right, #5f0000 0%, #b30000 100%) !important;
	content: "" !important;
	transition: width 0.2s !important;
}

.navbar-toggle {
	border-radius: 0;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
	background-color: #fff;
	border-color: #608dfd;
}

.navbar-default .navbar-toggle:hover>.icon-bar {
	background-color: #608dfd;
} */

.section-title {
	margin-bottom: 70px;
}

.section-title h2 {
	position: relative;
	margin-top: 10px;
	margin-bottom: 5s5px;
	padding-bottom: 15px;
}

.section-title h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #b30000 0%, #5f0000 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}

.section-title p {
	font-size: 18px;
}

.btn-custom {
	font-family: "Raleway", sans-serif;
	text-transform: uppercase;
	color: #fff;
	background-color: #b30000;
	background-image: linear-gradient(to right, #b30000 0%, #5f0000 100%);
	padding: 14px 34px;
	letter-spacing: 1px;
	margin: 0;
	font-size: 15px;
	font-weight: 500;
	border-radius: 25px;
	transition: all 0.7s ;
	border: 0;
	text-decoration: none;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
	color: #fff;
	background-image: none;
	background-color: #5f0000;
}

.btn:active,
.btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
	outline: none;
	outline-offset: none;
}

/* Header Section */

header {
	height: 90vh;
	width: 100%;
	/* margin-top: 80px; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.project header{
	height: 50vh;
}

.intro {
	display: table;
	width: 100%;
	height: 100%;
	padding: 0;
	background: url(../public/img/intro-bg.jpg) center center no-repeat;
	background-color: #e5e5e5;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

.intro .overlay {
	background: rgb(21 21 21 / 90%);
	height: 100%;
}

.intro h1 {
	font-family: "Raleway", sans-serif;
	color: #fff;
	font-size: 70px;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 10px;
}

.intro h1 span {
	font-weight: 800;
	color: #b30000;
}

.intro p {
	color: #fff;
	font-size: 22px;
	font-weight: 300;
	line-height: 30px;
	margin: 0 auto;
	margin-bottom: 60px;
}

header .intro-text {
	text-align: center;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* Features Section */
#features {
	background: #f6f6f6;
	padding: 70px 0px 40px 0px;
}

#features .row {
	display: inline-block;
}
#features .features-text{
	font-size: 20px;
}

#features i.fa {
	font-size: 38px;
	margin-bottom: 20px;
	transition: all 0.5s;
	color: #fff;
	width: 100px;
	height: 100px;
	padding: 30px 0;
	border-radius: 50%;
	background: linear-gradient(to top, #5f0000 0%, #b30000 100%);
	box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

/* About Section */
#about {
	padding: 100px 0;
}

#about h3 {
	font-size: 22px;
	margin: 0 0 20px;
}

#about h2 {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
}

#about h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #b30000 0%, #5f0000 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
}

#about .about-text li {
	margin-bottom: 6px;
	margin-left: 6px;
	list-style: none;
	padding: 0;
}

#about .about-text li:before {
	content: "\f00c";
	font-family: "FontAwesome";
	color: #b30000;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
}

#about img {
	width: 520px;
	margin-top: 10px;
	background: #fff;
	border-right: 0;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}

#about p {
	line-height: 24px;
	margin: 30px 0;
}

/* Services Section */
#services {
	padding: 100px 0;
	background: linear-gradient(to top, #5f0000 0%, #b30000 70%);
	color: #fff;
}

#services .service-desc {
	margin: 10px 10px 20px;
}

#services h2 {
	color: #fff;
}

#services .section-title h2::after {
	position: absolute;
	content: "";
	background: rgba(255, 255, 255, 0.3);
	height: 4px;
	width: 60px;
	bottom: 0;
	margin-left: -30px;
	left: 50%;
}

#services svg {




	width: 120px;
	height: 120px;
	padding: 40px 0;
	background: linear-gradient(132deg, #5f0000 0%, #b300005b 25%, #de0a0aea 50%, #870e0e47 75%, #b30000 100%);
	border-radius: 50%;
	background-size: 400% 400%;
	animation: BackgroundGradient 5s ease infinite;
}
@keyframes BackgroundGradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
#services h3 {
	font-weight: 500;
	padding: 5px 0;
	color: #fff;
}

#services p {
	color: rgba(255, 255, 255, 0.75);
}

#services .service-desc {
	margin-bottom: 40px;
}

/* Portfolio Section */
#portfolio {
	padding: 100px 0;
}

.portfolio-item {
	margin: 1px -15px 0 -14px;
	padding: 15px;
}

.portfolio-item .hover-bg {
	overflow: hidden;
	position: relative;
	margin: 0;
}

#portfolio .hover-bg img {
	height: 33.33333%;
	min-width: 100%;
	object-fit: cover;
}

.hover-bg .hover-text {
	position: absolute;
	text-align: center;
	margin: 0 auto;
	color: #fff;
	background: linear-gradient(to top,
			rgb(179, 0, 0) 0%,
			rgba(0, 0, 0, 0.8) 100%);
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: all 0.5s;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.hover-bg .hover-text>h4 {
	opacity: 0;
	color: #fff;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: all 0.3s;
	font-size: 18px;
	letter-spacing: 1px;
	font-weight: 500;
	text-transform: uppercase;
}

.hover-bg:hover .hover-text>h4 {
	opacity: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

.hover-bg:hover .hover-text {
	opacity: 1;
}

/* Testimonials Section */
#testimonials {
	padding: 100px 0;
	background: #f6f6f6;
	/* height: 500px; */
}

#testimonials i {
	color: #e6e6e6;
	font-size: 32px;
	margin-bottom: 20px;
}

.testimonial {
	position: relative;
	padding: 20px;
}

.testimonial-image {
	float: left;
	margin-right: 15px;
}

div#testimonials img {
	max-height: 100px;
	max-width: 150px;
	object-fit: contain;
}

.testimonial-image,
.testimonial-image img {
	display: block;
	width: 64px;
	/*height: 64px;*/
	/*border-radius: 50%;*/
}

.testimonial-content {
	position: relative;
	overflow: hidden;
}

.testimonial-content p {
	margin-bottom: 0;
	font-size: 14px;
	font-style: italic;
}

.testimonial-meta {
	margin-top: 10px;
	font-size: 15px;
	font-weight: 600;
	color: #666;
}

/* Team Section */
#team {
	padding: 100px 0;
}

#team h4 {
	margin: 5px 0;
}

#team .team-img {
	width: 240px;
}

#team .thumbnail {
	background: transparent;
	border: 0;
}

#team .thumbnail .caption {
	padding: 10px 0 0;
	color: #888;
}

/* Contact Section */
#contact {
	min-height: 100vh;
	padding: 100px 0 60px;
	background: linear-gradient(to right, #5f0000 0%, #b30000 100%);
	color: rgba(255, 255, 255, 0.75);
}

#contact .section-title {
	margin-bottom: 40px;
}

#contact .section-title p {
	font-size: 16px;
}

#contact .myflex {
	height: 70px;
	display: flex;
}

#contact h2 {
	color: #fff;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}

#contact .section-title h2::after {
	position: absolute;
	content: "";
	background: rgba(255, 255, 255, 0.3);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 30px;
}

#contact h3 {
	color: #fff;
	margin-top: 80px;
	margin-bottom: 25px;
	padding-bottom: 20px;
	font-weight: 400;
}

#contact form {
	padding-top: 20px;
}

#contact .text-danger {
	color: #cc0033;
	text-align: left;
}

#contact .btn-custom {
	margin: 30px 0;
	background: transparent;
	border: 2px solid #fff;
}

#contact .btn-custom:hover {
	color: #1f386e;
	background: #fff;
}

label {
	font-size: 12px;
	font-weight: 400;
	font-family: "Open Sans", sans-serif;
	float: left;
}

#contact .form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #444;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}

#contact .form-control:focus {
	border-color: #999;
	outline: 0;
	-webkit-box-shadow: transparent;
	box-shadow: transparent;
}

.form-control::-webkit-input-placeholder {
	color: #777;
}

.form-control:-moz-placeholder {
	color: #777;
}

.form-control::-moz-placeholder {
	color: #777;
}

.form-control:-ms-input-placeholder {
	color: #777;
}

#contact .contact-item {
	margin: 20px 0;
}

#contact .contact-item span {
	color: rgba(255, 255, 255, 1);
	margin-bottom: 10px;
	display: block;
}

#contact .contact-item i.fa {
	margin-right: 10px;
}

#contact .social {
	border-top: 1px solid rgba(255, 255, 255, 0.15);
	padding-top: 50px;
	margin-top: 50px;
	text-align: center;
}

#contact .social ul li {
	display: inline-block;
	margin: 0 20px;
}

#contact .social i.fa {
	font-size: 22px;
	width: 48px;
	height: 48px;
	padding: 12px 0;
	border: 2px solid #fff;
	color: #fff;
	border-radius: 50%;
	transition: all 0.3s;
}

#contact .social i.fa:hover {
	color: #608dfd;
	background: #fff;
}

#contact .plicy-privacy {
	display: flex;
	align-items: center;
}

#contact .plicy-privacy input {
	margin-right: 10px;
	accent-color: #34495e;
}

#contact .plicy-privacy p {
	cursor: default;
}

#contact a {
	color: white;
	font-weight: bold;
	cursor: pointer;
	text-decoration: none;
}

#contact .plicy-privacy a {
	text-decoration: underline;
}

#contact p {
	cursor: default;
}

/* Footer Section*/
#footer {
	background: #f6f6f6;
	padding: 30px 0;
}

#footer p {
	color: #888;
	font-size: 14px;
}

#footer a {
	color: #608dfd;
}

#footer a:hover {
	border-bottom: 2px solid #608dfd;
}



/* TO TEST */
.scroll {
	position: relative;
	display: flex;
	/* width: 700px; */
	overflow: hidden;
	-webkit-mask-image: linear-gradient(90deg,
			transparent,
			#fff 20%,
			#fff 80%,
			transparent);
}

.scroll div {
	white-space: nowrap;
	animation: scroll var(--time) linear infinite;
	animation-delay: calc(var(--time) * -1);
}

.scroll div:nth-child(2) {
	animation: scroll2 var(--time) linear infinite;
	animation-delay: calc(var(--time) / -2);
}

@keyframes scroll {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(-100%);
	}
}

@keyframes scroll2 {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-200%);
	}
}

.scroll div span {
	display: inline-flex;
	margin: 10px;
	letter-spacing: 0.2em;
	background: #333;
	color: #fff;
	padding: 5px 10px;
	border-radius: 5px;
	transition: 0.5s;
}

.scroll div span:hover {
	background: #3fd2f9;
	cursor: pointer;
}

.imgBox div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.imgBox img {
	max-width: 110px;
	scale: 0.8;
}

.nos-clients {
	display: flex;
	overflow-x: hidden;
}

/* SWIPER */
.swiper-wrapper {
	transition-timing-function: linear !important;
}

.swiper-slide img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	aspect-ratio: 1/1;
	-webkit-user-select: none;
	/* Safari */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* IE10+/Edge */
	user-select: none;
	/* Standard */
	pointer-events: none;
	/* Prevents clicking and dragging */
	mix-blend-mode: multiply;
}

.swiper-wrapper {
	max-height: 100% !important;
}

/* Project.js */
.project {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100%;
}

.project-hero-img {
	width: 100% !important;
}

.slideshowimages {
	background-color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 50px 0px;
}

.slideshowimages .card {
	width: 80%;
	margin: 0 auto;
	padding: 10px;
}

.slideshowimages .card img {
	width: 100%;
	border: 2px solid #4d0000;
}

.project .mySwiper {
	height: 90vh;
	padding: 20px;
	margin-bottom: 80px;
}

.project .mySwiper img {
	min-height: 100%;
	width: 100%;
	object-fit: contain;
}

.project-description {
	background-color: #f6f6f6;
	padding: 100px 0;
}

.project-description .container {
	width: 80%;
	margin: 0 auto;
}

/* END Project page*/

.navbar-default .navbar-nav>.active>a {
	background-color: transparent !important;
}

/* Navigation.css */
@media (min-width: 768px) {

	/* Adjust this value based on the 'lg' breakpoint or your custom needs */
	.custom-toggler {
		display: none !important;
	}
}

/* BOOTSTRAP */

.text-center {
	text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1;
	color: inherit;
}

.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	width: 100%;
}

@media (min-width: 768px) {
	.container {
		width: 750px;
	}
}

@media (min-width: 992px) {
	.container {
		width: 970px;
	}

}

@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}
	
}

.container-fluid {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.row {
	margin-right: -15px;
	margin-left: -15px;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
	float: left;
}

.col-xs-12 {
	width: 100%;
}

.col-xs-11 {
	width: 91.66666667%;
}

.col-xs-10 {
	width: 83.33333333%;
}

.col-xs-9 {
	width: 75%;
}

.col-xs-8 {
	width: 66.66666667%;
}

.col-xs-7 {
	width: 58.33333333%;
}

.col-xs-6 {
	width: 50%;
}

.col-xs-5 {
	width: 41.66666667%;
}

.col-xs-4 {
	width: 33.33333333%;
}

.col-xs-3 {
	width: 25%;
}

.col-xs-2 {
	width: 16.66666667%;
}

.col-xs-1 {
	width: 8.33333333%;
}

.col-xs-pull-12 {
	right: 100%;
}

.col-xs-pull-11 {
	right: 91.66666667%;
}

.col-xs-pull-10 {
	right: 83.33333333%;
}

.col-xs-pull-9 {
	right: 75%;
}

.col-xs-pull-8 {
	right: 66.66666667%;
}

.col-xs-pull-7 {
	right: 58.33333333%;
}

.col-xs-pull-6 {
	right: 50%;
}

.col-xs-pull-5 {
	right: 41.66666667%;
}

.col-xs-pull-4 {
	right: 33.33333333%;
}

.col-xs-pull-3 {
	right: 25%;
}

.col-xs-pull-2 {
	right: 16.66666667%;
}

.col-xs-pull-1 {
	right: 8.33333333%;
}

.col-xs-pull-0 {
	right: auto;
}

.col-xs-push-12 {
	left: 100%;
}

.col-xs-push-11 {
	left: 91.66666667%;
}

.col-xs-push-10 {
	left: 83.33333333%;
}

.col-xs-push-9 {
	left: 75%;
}

.col-xs-push-8 {
	left: 66.66666667%;
}

.col-xs-push-7 {
	left: 58.33333333%;
}

.col-xs-push-6 {
	left: 50%;
}

.col-xs-push-5 {
	left: 41.66666667%;
}

.col-xs-push-4 {
	left: 33.33333333%;
}

.col-xs-push-3 {
	left: 25%;
}

.col-xs-push-2 {
	left: 16.66666667%;
}

.col-xs-push-1 {
	left: 8.33333333%;
}

.col-xs-push-0 {
	left: auto;
}

.col-xs-offset-12 {
	margin-left: 100%;
}

.col-xs-offset-11 {
	margin-left: 91.66666667%;
}

.col-xs-offset-10 {
	margin-left: 83.33333333%;
}

.col-xs-offset-9 {
	margin-left: 75%;
}

.col-xs-offset-8 {
	margin-left: 66.66666667%;
}

.col-xs-offset-7 {
	margin-left: 58.33333333%;
}

.col-xs-offset-6 {
	margin-left: 50%;
}

.col-xs-offset-5 {
	margin-left: 41.66666667%;
}

.col-xs-offset-4 {
	margin-left: 33.33333333%;
}

.col-xs-offset-3 {
	margin-left: 25%;
}

.col-xs-offset-2 {
	margin-left: 16.66666667%;
}

.col-xs-offset-1 {
	margin-left: 8.33333333%;
}

.col-xs-offset-0 {
	margin-left: 0;
}

@media (min-width: 768px) {

	.col-sm-1,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12 {
		float: left;
	}

	.col-sm-12 {
		width: 100%;
	}

	.col-sm-11 {
		width: 91.66666667%;
	}

	.col-sm-10 {
		width: 83.33333333%;
	}

	.col-sm-9 {
		width: 75%;
	}

	.col-sm-8 {
		width: 66.66666667%;
	}

	.col-sm-7 {
		width: 58.33333333%;
	}

	.col-sm-6 {
		width: 50%;
	}

	.col-sm-5 {
		width: 41.66666667%;
	}

	.col-sm-4 {
		width: 33.33333333%;
	}

	.col-sm-3 {
		width: 25%;
	}

	.col-sm-2 {
		width: 16.66666667%;
	}

	.col-sm-1 {
		width: 8.33333333%;
	}

	.col-sm-pull-12 {
		right: 100%;
	}

	.col-sm-pull-11 {
		right: 91.66666667%;
	}

	.col-sm-pull-10 {
		right: 83.33333333%;
	}

	.col-sm-pull-9 {
		right: 75%;
	}

	.col-sm-pull-8 {
		right: 66.66666667%;
	}

	.col-sm-pull-7 {
		right: 58.33333333%;
	}

	.col-sm-pull-6 {
		right: 50%;
	}

	.col-sm-pull-5 {
		right: 41.66666667%;
	}

	.col-sm-pull-4 {
		right: 33.33333333%;
	}

	.col-sm-pull-3 {
		right: 25%;
	}

	.col-sm-pull-2 {
		right: 16.66666667%;
	}

	.col-sm-pull-1 {
		right: 8.33333333%;
	}

	.col-sm-pull-0 {
		right: auto;
	}

	.col-sm-push-12 {
		left: 100%;
	}

	.col-sm-push-11 {
		left: 91.66666667%;
	}

	.col-sm-push-10 {
		left: 83.33333333%;
	}

	.col-sm-push-9 {
		left: 75%;
	}

	.col-sm-push-8 {
		left: 66.66666667%;
	}

	.col-sm-push-7 {
		left: 58.33333333%;
	}

	.col-sm-push-6 {
		left: 50%;
	}

	.col-sm-push-5 {
		left: 41.66666667%;
	}

	.col-sm-push-4 {
		left: 33.33333333%;
	}

	.col-sm-push-3 {
		left: 25%;
	}

	.col-sm-push-2 {
		left: 16.66666667%;
	}

	.col-sm-push-1 {
		left: 8.33333333%;
	}

	.col-sm-push-0 {
		left: auto;
	}

	.col-sm-offset-12 {
		margin-left: 100%;
	}

	.col-sm-offset-11 {
		margin-left: 91.66666667%;
	}

	.col-sm-offset-10 {
		margin-left: 83.33333333%;
	}

	.col-sm-offset-9 {
		margin-left: 75%;
	}

	.col-sm-offset-8 {
		margin-left: 66.66666667%;
	}

	.col-sm-offset-7 {
		margin-left: 58.33333333%;
	}

	.col-sm-offset-6 {
		margin-left: 50%;
	}

	.col-sm-offset-5 {
		margin-left: 41.66666667%;
	}

	.col-sm-offset-4 {
		margin-left: 33.33333333%;
	}

	.col-sm-offset-3 {
		margin-left: 25%;
	}

	.col-sm-offset-2 {
		margin-left: 16.66666667%;
	}

	.col-sm-offset-1 {
		margin-left: 8.33333333%;
	}

	.col-sm-offset-0 {
		margin-left: 0;
	}
}

@media (min-width: 992px) {

	.col-md-1,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-10,
	.col-md-11,
	.col-md-12 {
		float: left;
	}

	.col-md-12 {
		width: 100%;
	}

	.col-md-11 {
		width: 91.66666667%;
	}

	.col-md-10 {
		width: 83.33333333%;
	}

	.col-md-9 {
		width: 75%;
	}

	.col-md-8 {
		width: 66.66666667%;
	}

	.col-md-7 {
		width: 58.33333333%;
	}

	.col-md-6 {
		width: 50%;
	}

	.col-md-5 {
		width: 41.66666667%;
	}

	.col-md-4 {
		width: 33.33333333%;
	}

	.col-md-3 {
		width: 25%;
	}

	.col-md-2 {
		width: 16.66666667%;
	}

	.col-md-1 {
		width: 8.33333333%;
	}

	.col-md-pull-12 {
		right: 100%;
	}

	.col-md-pull-11 {
		right: 91.66666667%;
	}

	.col-md-pull-10 {
		right: 83.33333333%;
	}

	.col-md-pull-9 {
		right: 75%;
	}

	.col-md-pull-8 {
		right: 66.66666667%;
	}

	.col-md-pull-7 {
		right: 58.33333333%;
	}

	.col-md-pull-6 {
		right: 50%;
	}

	.col-md-pull-5 {
		right: 41.66666667%;
	}

	.col-md-pull-4 {
		right: 33.33333333%;
	}

	.col-md-pull-3 {
		right: 25%;
	}

	.col-md-pull-2 {
		right: 16.66666667%;
	}

	.col-md-pull-1 {
		right: 8.33333333%;
	}

	.col-md-pull-0 {
		right: auto;
	}

	.col-md-push-12 {
		left: 100%;
	}

	.col-md-push-11 {
		left: 91.66666667%;
	}

	.col-md-push-10 {
		left: 83.33333333%;
	}

	.col-md-push-9 {
		left: 75%;
	}

	.col-md-push-8 {
		left: 66.66666667%;
	}

	.col-md-push-7 {
		left: 58.33333333%;
	}

	.col-md-push-6 {
		left: 50%;
	}

	.col-md-push-5 {
		left: 41.66666667%;
	}

	.col-md-push-4 {
		left: 33.33333333%;
	}

	.col-md-push-3 {
		left: 25%;
	}

	.col-md-push-2 {
		left: 16.66666667%;
	}

	.col-md-push-1 {
		left: 8.33333333%;
	}

	.col-md-push-0 {
		left: auto;
	}

	.col-md-offset-12 {
		margin-left: 100%;
	}

	.col-md-offset-11 {
		margin-left: 91.66666667%;
	}

	.col-md-offset-10 {
		margin-left: 83.33333333%;
	}

	.col-md-offset-9 {
		margin-left: 75%;
	}

	.col-md-offset-8 {
		margin-left: 66.66666667%;
	}

	.col-md-offset-7 {
		margin-left: 58.33333333%;
	}

	.col-md-offset-6 {
		margin-left: 50%;
	}

	.col-md-offset-5 {
		margin-left: 41.66666667%;
	}

	.col-md-offset-4 {
		margin-left: 33.33333333%;
	}

	.col-md-offset-3 {
		margin-left: 25%;
	}

	.col-md-offset-2 {
		margin-left: 16.66666667%;
	}

	.col-md-offset-1 {
		margin-left: 8.33333333%;
	}

	.col-md-offset-0 {
		margin-left: 0;
	}

}

@media (min-width: 1200px) {

	.col-lg-1,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12 {
		float: left;
	}

	.col-lg-12 {
		width: 100%;
	}

	.col-lg-11 {
		width: 91.66666667%;
	}

	.col-lg-10 {
		width: 83.33333333%;
	}

	.col-lg-9 {
		width: 75%;
	}

	.col-lg-8 {
		width: 66.66666667%;
	}

	.col-lg-7 {
		width: 58.33333333%;
	}

	.col-lg-6 {
		width: 50%;
	}

	.col-lg-5 {
		width: 41.66666667%;
	}

	.col-lg-4 {
		width: 33.33333333%;
	}

	.col-lg-3 {
		width: 25%;
	}

	.col-lg-2 {
		width: 16.66666667%;
	}

	.col-lg-1 {
		width: 8.33333333%;
	}

	.col-lg-pull-12 {
		right: 100%;
	}

	.col-lg-pull-11 {
		right: 91.66666667%;
	}

	.col-lg-pull-10 {
		right: 83.33333333%;
	}

	.col-lg-pull-9 {
		right: 75%;
	}

	.col-lg-pull-8 {
		right: 66.66666667%;
	}

	.col-lg-pull-7 {
		right: 58.33333333%;
	}

	.col-lg-pull-6 {
		right: 50%;
	}

	.col-lg-pull-5 {
		right: 41.66666667%;
	}

	.col-lg-pull-4 {
		right: 33.33333333%;
	}

	.col-lg-pull-3 {
		right: 25%;
	}

	.col-lg-pull-2 {
		right: 16.66666667%;
	}

	.col-lg-pull-1 {
		right: 8.33333333%;
	}

	.col-lg-pull-0 {
		right: auto;
	}

	.col-lg-push-12 {
		left: 100%;
	}

	.col-lg-push-11 {
		left: 91.66666667%;
	}

	.col-lg-push-10 {
		left: 83.33333333%;
	}

	.col-lg-push-9 {
		left: 75%;
	}

	.col-lg-push-8 {
		left: 66.66666667%;
	}

	.col-lg-push-7 {
		left: 58.33333333%;
	}

	.col-lg-push-6 {
		left: 50%;
	}

	.col-lg-push-5 {
		left: 41.66666667%;
	}

	.col-lg-push-4 {
		left: 33.33333333%;
	}

	.col-lg-push-3 {
		left: 25%;
	}

	.col-lg-push-2 {
		left: 16.66666667%;
	}

	.col-lg-push-1 {
		left: 8.33333333%;
	}

	.col-lg-push-0 {
		left: auto;
	}

	.col-lg-offset-12 {
		margin-left: 100%;
	}

	.col-lg-offset-11 {
		margin-left: 91.66666667%;
	}

	.col-lg-offset-10 {
		margin-left: 83.33333333%;
	}

	.col-lg-offset-9 {
		margin-left: 75%;
	}

	.col-lg-offset-8 {
		margin-left: 66.66666667%;
	}

	.col-lg-offset-7 {
		margin-left: 58.33333333%;
	}

	.col-lg-offset-6 {
		margin-left: 50%;
	}

	.col-lg-offset-5 {
		margin-left: 41.66666667%;
	}

	.col-lg-offset-4 {
		margin-left: 33.33333333%;
	}

	.col-lg-offset-3 {
		margin-left: 25%;
	}

	.col-lg-offset-2 {
		margin-left: 16.66666667%;
	}

	.col-lg-offset-1 {
		margin-left: 8.33333333%;
	}

	.col-lg-offset-0 {
		margin-left: 0;
	}
}

.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical>.btn-group:before,
.btn-group-vertical>.btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
	display: table;
	content: " ";
}

.clearfix:after,
.dl-horizontal dd:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical>.btn-group:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-header:after,
.modal-footer:after {
	clear: both;
}

.img-responsive,
.thumbnail>img,
.thumbnail a>img,
.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
	display: block;
	max-width: 100%;
	height: auto;
}

/* policy page  */
#policy {
    margin-bottom: 40px;
    padding-top: 60px;
}

#policy h1 {
	font-weight: 900;
	color: black;
}

#policy h2 {
	color: #600100;
}


/* Animazioni  */
header .intro-text h1 {
	position: relative
}

header .intro-text h1::before {
	position: absolute;
	background-image: url(https://www.stroybat.fr/wp-content/uploads/2023/11/cropped-Stroybat-logo-red-300x300-1.png);
	content: "";
	width: 80px;
	height: 80px;
	background-size: contain;
	left: -110px;
	top: -4px;
	animation-name: rotation;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	animation-duration: 2.5s;
	transform: rotateY(0deg);
	transform-style: preserve-3d;
}

@keyframes rotation {
	0% {
		transform: rotate3d(0, 1, 0, 0deg);
	}

	50% {
		transform: rotate3d(0, 1, 0, 180deg);
	}

	100% {
		transform: rotate3d(0, 1, 0, 360deg);
	}
}

@media (max-width: 768px) {
	#about img {
		margin: 50px 0;
	}

	header .intro-text h1::before {
		left: 120px;
		top: -84px;
	}
}
@media screen and (max-width: 992px) {
	.project header{
		height: 40vh;
	}
	#navbar {
		/* background: linear-gradient(to top, #5f000096 0%, #b3000096 100%); */
		background: linear-gradient(to top, #ab0000cc 0%, #000000d4 100%);
		bottom: 20px;
		left: 50%;
		width: fit-content;
		transform: translateX(-50%);
		border-radius: 35px;
        border: 1px solid white;
	}

	#navbar .container {
		padding-right: 0px;
		padding-left: 0px;
		width: fit-content;
	}

	#navbar .navlink {
		width: 60px;
		height: 60px;
		margin: 0;
		background-color: var(--white);
		margin: 5px;
		border-radius: 100%;
		transition: background-color 0.6s;
	}

	#navbar .link {
		width: 100%;
		height: 100%;
		margin-top: 25%;
		/* display: flex;
		flex-direction: column;
		justify-content: center; */

	}

	#navbar .logo {
		max-width: 100%;
	}

	#navbar .logo img {
		display: none;
	}

	#navbar .logo .link {
		display: block;
	}

	#navbar svg {
		display: block;
		color: var(--red);
		transition: color 0.6s;
		margin: 0 auto;
	}

	#navbar .navlink p {
		color: var(--red);
		transition: color 0.6s;
		font-weight: 600;
		font-size: 10px;
		text-align: center;
		margin: 0;
	}


	.navlink-active {
		/* background: linear-gradient(to bottom, #b30000 0%, #5f0000 100%); */
        background: linear-gradient(to bottom, #662b2b 0%, #fe0000 100%);
	}

	#navbar .navlink-active p,
	#navbar .navlink-active svg {
		color: var(--white);
		font-weight: bold;
	}
	#policy {
		padding-top: 0px;
	}
}
